<template>
  <div>
    <SignIn :sign_in_dialog="sign_in_dialog" @clicked="SignInDialogEmit" />
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list>
        <v-list-item>
          <v-list-item-avatar width="100%" height="100%">
            <img src="@/assets/img/logo.png" alt="Logo" />
          </v-list-item-avatar>
          <v-list-item-content> </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item
          v-for="([icon, text, link], i) in items"
          :key="i"
          link
          @click="$vuetify.goTo(link)"
        >
          <v-list-item-icon class="justify-center">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">{{
              text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="white"
      :flat="flat"
      class="px-6 black"
      :class="{ expand: flat }"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-0"
        v-if="isXs"
      />
      <v-toolbar-title>
        <v-img src="@/assets/img/logo.png" max-width="100%" />
      </v-toolbar-title>
      <v-spacer />
      <v-icon v-if="isXs" @click="sign_in_dialog = true"
        >mdi-account-outline</v-icon
      >
      <div v-else>
        <!-- <v-btn class="mt-n1 mr-2 primary" x-small @click="reportissue()" fab>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-alert-circle-outline</v-icon>
            </template>
            <span>Click To Report Issue</span>
          </v-tooltip>
        </v-btn> -->
        <v-btn text @click="$vuetify.goTo('#hero')">
          <span class="mr-2">Home</span>
        </v-btn>
        <v-btn text @click="$vuetify.goTo('#features')">
          <span class="mr-2">About</span>
        </v-btn>

        <v-btn text @click="$vuetify.goTo('#contact')">
          <span class="mr-2">Contact us</span>
        </v-btn>
        <v-btn rounded outlined text @click="sign_in_dialog = true">
          <span class="mr-2">Sign in</span>
        </v-btn>
      </div>
    </v-app-bar>
    <v-btn
      fab
      v-show="fab"
      v-scroll="onScroll"
      dark
      fixed
      bottom
      right
      color="primary"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </div>
</template>

<style scoped>
.FontSize {
  font-size: 12px;
}
.v-input .v-label {
  font-size: 12px;
  z-index: 10;
}
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>

<script>
import SignIn from "@/components/users/Dialogs/SignIn.vue";
export default {
  data: () => ({
    drawer: null,
    sign_in_dialog: false,
    isXs: false,
    fab: null,
    items: [
      ["mdi-home-outline", "Home", "#hero"],
      ["mdi-information-outline", "About", "#features"],
      ["mdi-email-outline", "Contact us", "#contact"],
      // ["mdi-account-outline", "Sign in", "#signIn"],
    ],
  }),
  props: {
    color: String,
    flat: Boolean,
  },
  components: {
    SignIn,
  },
  methods: {
    reportissue() {
      window.open("http://www.bugtrakr.com/BugReportForm/PHRMAWRK");
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    SignInDialogEmit() {
      this.sign_in_dialog = false;
    },
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
