<template>
  <v-card class="elevation-0">
    <SignIn :sign_in_dialog="sign_in_dialog" @clicked="SignInDialogEmit" />
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" xs="12">
        <v-toolbar>
          <v-card class="elevation-0" width="130px">
            <v-img src="@/assets/logo.jpg"></v-img>
          </v-card>
          <v-spacer></v-spacer>
          <v-tabs
            v-if="$vuetify.breakpoint.name != 'xs'"
            v-model="tab"
            right
            hide-slider
            color="primary"
            class="text-capitalize"
          >
            <v-tab class="text-capitalize">Home</v-tab>
            <v-tab class="text-capitalize">About</v-tab>
            <v-tab class="text-capitalize">Contact</v-tab>
          </v-tabs>
          <v-btn
            v-if="$vuetify.breakpoint.name == 'xs'"
            text
            color="primary"
            style="border-radius: 20px"
            class="ml-2"
          >
            <v-icon>mdi-menu</v-icon>
            Menu</v-btn
          >
          <v-btn
            v-if="$vuetify.breakpoint.name != 'xs'"
            color="primary"
            style="border-radius: 20px"
            class="ml-2"
            @click="sign_in_dialog = true"
            >Sign In</v-btn
          >
        </v-toolbar>
      </v-col>
      <v-col cols="12" sm="12" md="12" xs="12">
        <v-tabs-items v-model="tab">
          <v-tab-item transition="v-scroll-x-transition">
            <HomeSection />
            <AboutSection />
            <DownloadSection />
            <Navigation />
            <ContactSection />
            <Footer />
          </v-tab-item>
          <v-tab-item transition="v-scroll-y-transition">
            <v-img src="@/assets/Capture5.png" />
          </v-tab-item>
          <v-tab-item transition="v-scroll-x-transition">
            <v-card :height="CardHeight" color="#D0D0D0">
              <v-card-title class="font-weight-light"
                >Contact Information</v-card-title
              >
              <v-card-text class="py-0">
                <v-row>
                  <v-col cols="12" sm="12" md="4" xs="12">
                    <v-text-field
                      dense
                      outlined
                      solo
                      placeholder="Your Name"
                    ></v-text-field>
                    <v-text-field
                      dense
                      outlined
                      solo
                      placeholder="Your Email"
                    ></v-text-field>
                    <v-select
                      dense
                      outlined
                      solo
                      :items="CountryCodeItems"
                      placeholder="Country Code"
                    ></v-select>
                    <v-text-field
                      dense
                      outlined
                      solo
                      placeholder="Your Number"
                    ></v-text-field>
                    <v-textarea
                      dense
                      outlined
                      solo
                      placeholder="Description"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="8" xs="12">
                    <v-card class="elevation-0" :height="MapHeight">
                      <gmap-map
                        :center="center"
                        :zoom="12"
                        style="width: 100%; height: 100%"
                      >
                        <gmap-marker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          @click="center = m.position"
                        ></gmap-marker>
                      </gmap-map>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import SignIn from "@/components/users/Dialogs/SignIn.vue";
import AboutSection from "@/components/AboutSection.vue";
import ContactSection from "@/components/ContactSection.vue";
import DownloadSection from "@/components/DownloadSection.vue";
import Footer from "@/components/Footer.vue";
import HomeSection from "@/components/HomeSection.vue";
import Navigation from "@/components/Navigation.vue";
export default {
  components: {
    SignIn,
    AboutSection,
    ContactSection,
    DownloadSection,
    Footer,
    HomeSection,
    Navigation,
  },
  data: () => ({
    center: { lat: 13.080954, lng: 77.559688 },
    markers: [],
    places: [],
    tab: 0,
    CountryCodeItems: ["+91"],
    sign_in_dialog: false,
    model: 0,
    CardHeight: 0,
    MapHeight: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
    src: [
      "@/assets/Capture1.png",
      "@/assets/Capture2.png",
      "@/assets/Capture4.png",
      "@/assets/Capture4.png",
    ],
  }),
  mounted() {
    this.CardHeight = window.innerHeight - 65;
    this.MapHeight = window.innerHeight - 165;
  },
  methods: {
    SignInDialogEmit() {
      this.sign_in_dialog = false;
    },
  },
};
</script>
