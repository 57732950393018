<template>
  <section class="pb-8" id="contact">
    <v-container fluid>
      <SnackBar :SnackBarComponent="SnackBarComponent" />
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" sm="5">
              <h1 class="font-weight-dark mb-3">Contact us</h1>
              <h3 class="font-weight-dark mt-3">Address :</h3>
              <h3 class="font-weight-light mt-3">
                No 588, HMT Layout Vidyaranyapura,<br />
                Bangalore - 560097
              </h3>

              <h3 class="font-weight-dark mt-3">Phone Number :</h3>
              <h3 class="font-weight-light mt-3">080 41615842</h3>
              <br />
              <h3 class="font-weight-dark">Email :</h3>
              <h3 class="font-weight-light mt-3">contactus@wrktop.com</h3>
            </v-col>
            <v-col cols="12" sm="7">
              <h1 class="font-weight-dark mb-3">Write to us</h1>
              <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-text-field
                  maxlength="51"
                  v-model="Name"
                  :rules="nameRules"
                  label="Name"
                  required
                ></v-text-field>

                <v-text-field
                  maxlength="51"
                  v-model="EmailId"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>
                <v-text-field
                  maxlength="10"
                  v-model="PhoneNumber"
                  :rules="phoneRules"
                  label="Phone Number"
                  required
                ></v-text-field>
                <v-textarea
                  v-model="Message"
                  :rules="textAreaRules"
                  label="Message"
                  required
                />

                <v-btn
                  :loading="isLoading"
                  :disabled="!valid"
                  color="primary"
                  :dark="valid"
                  rounded
                  block
                  class="mt-3"
                  @click="submit"
                >
                  Send
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <div class="svg-border-waves text-white mt-12">
      <v-img src="~@/assets/img/borderWavesBlue.png" />
    </div>
    <v-snackbar
      v-model="snackbar.enabled"
      timeout="3000"
      right
      top
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.enabled = false">
          Cancel
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<style scoped>
#contact {
  padding-top: 30px;
  background-color: #f4f7f5;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}
</style>

<script>
import SnackBar from "@/components/Extras/SnackBar.vue";
import axios from "axios";
export default {
  components: {
    SnackBar,
  },
  data: () => ({
    valid: true,
    Name: "",
    EmailId: "",
    PhoneNumber: "",
    Message: "",
    storeResult: [],
    SnackBarComponent: {},
    isLoading: false,

    nameRules: [
      (v) => !!v || "Name is required!",
      (v) => (v && v.length >= 6) || "Please provide Name",
      (v) => (v && v.length <= 50) || "Maximum characters limit reached",
    ],

    emailRules: [
      (v) => !!v || "E-Mail is required!",
      (v) => /.+@.+\..+/.test(v) || "Enter appropriate E-Mail",
    ],

    phoneRules: [
      (v) => !!v || "Phone Number is required!",
      (v) => (v && v.length >= 9) || "Phone should contain 10 numbers",
    ],

    textAreaRules: [
      (v) => !!v || "Enter Message",
      (v) => (v && v.length >= 10) || "Minimum 10 characters",
    ],
    lazy: false,
    snackbar: {
      enabled: false,
      text: "",
      color: "",
    },
  }),
  methods: {
    submit() {
      this.ContactUsForm();
      /*db.collection("contactData").add({
        name: this.name,
        email: this.email,
        message: this.textArea
      }).then(() => {
        this.snackbar.text = "Mensagem enviada com sucesso"
        this.snackbar.color = "success"
        this.snackbar.enabled = true
      }).catch(() => {
        this.snackbar.text = "Erro ao enviar mensagem"
        this.snackbar.color = "danger"
        this.snackbar.enabled = true
      })*/
    },

    ContactUsForm() {
      this.isLoading = true;
      var data = {
        command: "ContactUs",
        subject: "Contact Form Regarding PharmaWRK",
        to_address: "contactus@wrktop.com",
        // email_body: this.Name + this.Email + this.PhoneNumber + this.Message,
        email_body:
          "Contact Name :" +
          this.Name +
          ", " +
          "Contact Email Address :" +
          this.EmailId +
          ", " +
          "Contact Phone Number :" +
          this.PhoneNumber +
          ", " +
          "Message :" +
          this.Message,
      };

      var config = {
        method: "post",
        url: "https://3gf2wgidf6.execute-api.ap-south-1.amazonaws.com/pharmaWRKConatctForm",

        data: data,
      };
      axios(config)
        .then((res) => {
          this.storeResult = res.data;
          this.isLoading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "primary",
            SnackbarText: this.storeResult.Status_Message,
            Top: true,
            timeout: 2000,
          };
          this.clear();
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: error.errors[0].message,
            Top: true,
            timeout: 2000,
          };
        });
    },

    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>
