<template>
  <v-footer dark padless>
    <v-card flat tile class="dark white--text text-center">
      <!-- {{ new Date().getFullYear() }} — <strong>PharmaWRK</strong> -->
      <v-card-text class="white--text">
        Copyright © All rights reserved by WRKTOP Technologies
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
export default {
  data: () => ({
    icons: [
      {
        text: "mdi-facebook",
        link: "",
      },
      {
        text: "mdi-twitter",
        link: "",
      },
      {
        text: "mdi-linkedin",
        link: "",
      },
      {
        text: "mdi-instagram",
        link: "",
      },
    ],
  }),
};
</script>
