<template>
  <section id="hero">
    <v-parallax dark src="@/assets/img/bg-hero.png" height="600">
      <v-row align="center" justify="center">
        <v-col cols="12 ">
          <v-row align="center" justify="center">
            <v-col cols="12" md="5" xl="4" sm="12">
              <div
                class="display-1 header font-weight-bold mb-4"
                style="color: black"
              >
                <h3>Built for Medical Reps</h3>
              </div>
              <h1 class="font-weight-light" style="color: black">
                An easy tool to assist Medical Representative's<br />
                on the field <br />
              </h1>
              <v-row class="mt-4 mr-16 pr-16">
                <v-col class="mr-n16">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.mobil80.pharmawrk"
                    target="_blank"
                  >
                    <v-img src="@/assets/img/android.png" width="160px"
                  /></a>
                </v-col>
                <v-col class="ml-8">
                  <a
                    href="https://apps.apple.com/in/app/pharmawrk/id1575723537"
                    target="_blank"
                  >
                    <v-img src="@/assets/img/apple.png" width="160px"
                  /></a>
                </v-col>
              </v-row>
              <v-btn
                style="color: black"
                rounded
                outlined
                large
                dark
                @click="$vuetify.goTo('#features')"
                class="mt-7"
              >
                Know More
                <v-icon class="ml-2">mdi-arrow-down</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="hidden-sm-and-down"> </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="svg-border-waves text-white">
        <v-img src="@/assets/img/borderWaves.svg" />
      </div>
    </v-parallax>
    <v-container fluid id="features" class="mt-n16 pt-n16">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <!-- <v-col cols="12" class="text-center">
              <h1 class="font-weight-light display-2">Title</h1>
              <h1 class="font-weight-light">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </h1>
            </v-col> -->
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular">{{ feature.title }}</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="640px">
      <v-card>
        <youtube
          :video-id="videoId"
          @ready="ready"
          @playing="playing"
        ></youtube>
      </v-card>
    </v-dialog>
    <div class="svg-border-waves">
      <img src="~@/assets/img/wave2.svg" />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      videoId: "i8IvvHJssWE",

      features: [
        {
          img: require("@/assets/img/a1.png"),
          title: "Optimize your visits",
          text: "Quick and easy access to help you decide on where to visit next, based on your current location",
        },
        {
          img: require("@/assets/img/a2.png"),
          title: "Access your Visit Logs",
          text: "Log of all of the representative’s recent visits, available to the business on a geo-spatial map on the web portal",
        },
        {
          img: require("@/assets/img/a3.png"),
          title: "Access learning literature",
          text: "Access drug and medical equipment related literature right from your mobile device and easily share it with doctors and health professionals",
        },
      ],
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },

  methods: {
    ready(event) {
      this.player = event.target;
    },
    playing(event) {
      console.log(event);
      // The player is playing a video.
    },
    change() {
      // when you change the value, the player will also change.
      // If you would like to change `playerVars`, please change it before you change `videoId`.
      // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
      // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
      this.videoId = "another video id";
    },
    stop() {
      this.player.stopVideo();
    },
    pause() {
      this.player.pauseVideo();
    },
  },
};
</script>

<style lang="scss">
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  // -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  // -webkit-transition: all 0.5s ease;

  .triangle {
    // -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
@media only screen and (max-width: 400px) {
  .header h1 {
    font-size: 31px;
  }
}
</style>

<style>
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
</style>

<style>
section {
  position: relative;
}
</style>
