import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
    state: {
        EmailID: "",
        FirstLoginStatus: null,
        auth: false,
        counter: 0,
        getIssueDetails: {},
        getPreviousRoute: "",
        refreshPath:"",
        get_current_user:{},
    },
    getters: {
        get_auth: (state) => {
            return state.auth || false;
        },
        get_user_email: (state) => {
            return state.EmailID;
        },
        get_login_status: (state) => {
            return state.FirstLoginStatus;
        },
        get_change: (state) => {
            return state.counter;
        },
        get_issue_details: (state) => {
            return state.getIssueDetails;
          },
          get_previous_route: (state) => {
            return state.getPreviousRoute;
          },
          get_refresh_path: (state) => {
            return state.refreshPath;
          },
          get_current_user:(state) => {
            return state.get_current_user;
          }
    },
    mutations: {
        SET_AUTH(state, status) {
            state.auth = status;
        },
        SET_USEREMAIL(state, EmailID) {
            state.EmailID = EmailID;
        },
        SET_LOGIN_STATUS(state, LoginFirstTimeLoginStatus) {
            state.FirstLoginStatus = LoginFirstTimeLoginStatus;
        },
        set_change(state, counter) {
            state.counter = counter;
        },
        SET_ISSUE_DETAILS(state, getIssueDetails) {
            state.getIssueDetails = getIssueDetails;
          },
          SET_PREVIOUS_ROUTE(state, route) {
            state.getPreviousRoute = route;
          },
          SET_refresh_path(state, route) {
            state.refreshPath = route;
          },
          SET_CURRENT_USER(state,get_current_user) {
            state.get_current_user = get_current_user
          }
    },
    actions: {},
    modules: {},
    plugins: [createPersistedState()],

});