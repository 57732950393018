var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"signIn"}},[_c('div',[_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c('v-dialog',{attrs:{"max-width":"400px","persistent":"","transition":"dialog-top-transition"},model:{value:(_vm.sign_in_dialog),callback:function ($$v) {_vm.sign_in_dialog=$$v},expression:"sign_in_dialog"}},[_c('v-card',{staticClass:"elevation-0"},[_c('v-toolbar',{staticClass:"elevation-0 gradient-bg",attrs:{"dense":"","dark":""}},[_c('v-toolbar-title',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.currentTitle))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.SignInDialogEmit()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"pt-4",attrs:{"align":"center","justify":"center"}},[_c('v-img',{attrs:{"max-width":"175px","src":require("@/assets/logo.jpg")}})],1),_c('v-card-text',[_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-text-field',{staticClass:"FontSize",attrs:{"maxlength":"41","dense":"","label":"Email ID *","autocomplete":"off","prepend-inner-icon":"mdi-account","rules":[
                      (v) => !!v || 'Email is Required',
                      (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
                      (v) =>
                        (v && v.length < 50) ||
                        'Email must be less than 50 characters',
                    ]},model:{value:(_vm.Login.EmailID),callback:function ($$v) {_vm.$set(_vm.Login, "EmailID", $$v)},expression:"Login.EmailID"}}),_c('v-text-field',{staticClass:"FontSize",attrs:{"maxlength":"15","autocomplete":"off","label":"Password *","type":_vm.isPwd ? 'text' : 'password',"required":"","append-icon":_vm.isPwd ? 'mdi-eye' : 'mdi-eye-off',"prepend-inner-icon":"mdi-lock","rules":[
                      (v) => !!v || 'Required',

                      (v) =>
                        (v && v.length < 15) ||
                        'Password must be less than 15 characters',
                      (v) =>
                        (v && v.length > 5) ||
                        'Password must be more than 5 characters',
                    ]},on:{"click:append":function($event){_vm.isPwd = !_vm.isPwd}},model:{value:(_vm.Login.Password),callback:function ($$v) {_vm.$set(_vm.Login, "Password", $$v)},expression:"Login.Password"}})],1)],1),_c('v-card-actions',{staticClass:"py-0"},[_c('v-checkbox',{staticClass:"my-0 ml-2",attrs:{"dense":"","label":"Remember Me","disabled":_vm.Login.EmailID == '' || _vm.Login.Password === ''},model:{value:(_vm.rememberme),callback:function ($$v) {_vm.rememberme=$$v},expression:"rememberme"}}),_c('v-spacer'),_c('v-btn',{staticClass:"FontSize mb-4",attrs:{"x-small":"","text":"","color":"primary"},on:{"click":function($event){_vm.step = 3}}},[_vm._v("Forgot Password ?")])],1),_c('v-card-actions',{staticClass:"pt-0 pb-8 px-4"},[_c('v-btn',{staticClass:"gradient-bg",attrs:{"block":"","loading":_vm.loading,"tile":"","color":"primary"},on:{"click":function($event){return _vm.ValidateLoginForm()}}},[_vm._v("Login")])],1)],1),_c('v-window-item',{attrs:{"value":2}},[_c('v-card-text',[_c('v-form',{ref:"formCP",attrs:{"lazy-validation":""}},[_c('v-text-field',{staticClass:"BorderRadius FontSize",attrs:{"maxlength":"15","dense":"","autocomplete":"off","label":"Enter New Password *","type":_vm.isPwdNew ? 'text' : 'password',"required":"","append-icon":_vm.isPwdNew ? 'mdi-eye' : 'mdi-eye-off',"prepend-inner-icon":"mdi-lock","rules":[
                      (v) => !!v || 'Required',

                      (v) =>
                        (v && v.length < 15) ||
                        'Password must be less than 15 characters',
                      (v) =>
                        (v && v.length > 5) ||
                        'Password must be more than 5 characters',
                    ]},on:{"click:append":function($event){_vm.isPwdNew = !_vm.isPwdNew}},model:{value:(_vm.Password.NewPassword),callback:function ($$v) {_vm.$set(_vm.Password, "NewPassword", $$v)},expression:"Password.NewPassword"}}),_c('v-text-field',{staticClass:"BorderRadius FontSize",attrs:{"maxlength":"15","dense":"","autocomplete":"off","label":"Confirm New Password *","type":_vm.isPwdConfirm ? 'text' : 'password',"required":"","append-icon":_vm.isPwdConfirm ? 'mdi-eye' : 'mdi-eye-off',"prepend-inner-icon":"mdi-lock"},on:{"click:append":function($event){_vm.isPwdConfirm = !_vm.isPwdConfirm}},model:{value:(_vm.Password.ConfirmPassword),callback:function ($$v) {_vm.$set(_vm.Password, "ConfirmPassword", $$v)},expression:"Password.ConfirmPassword"}})],1)],1),_c('v-card-actions',{staticClass:"pt-0 pb-8 px-4"},[_c('v-btn',{attrs:{"block":"","loading":_vm.loading,"tile":"","color":"primary"},on:{"click":function($event){return _vm.ValidateConfirmPasswordForm()}}},[_vm._v("Login")])],1)],1),_c('v-window-item',{attrs:{"value":3}},[_c('v-card-text',[_c('v-form',{ref:"formOTP"},[_c('div',{staticClass:"text-center",staticStyle:{"color":"#0c9f6b"}},[_vm._v(" Please Enter The Registered Email ")]),_c('v-text-field',{staticClass:"mt-4 FontSize",attrs:{"maxlength":"41","label":"Email","outlined":"","autocomplete":"off","rules":[
                      (v) => !!v || 'Email is Required',
                      (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
                      (v) =>
                        (v && v.length < 50) ||
                        'Email must be less than 50 characters',
                    ]},model:{value:(_vm.OTPEmail),callback:function ($$v) {_vm.OTPEmail=$$v},expression:"OTPEmail"}}),_c('div',{staticClass:"FontSize text-center",staticStyle:{"color":"#0c9f6b"}},[_vm._v(" *Please enter the e-mail used for login/registration ")])],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary","small":""},on:{"click":function($event){_vm.step = 1}}},[_vm._v("Back To Sign In")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","small":""},on:{"click":function($event){return _vm.ValidateOTP()}}},[_vm._v("Next")])],1)],1),_c('v-window-item',{attrs:{"value":4}},[_c('v-card-text',[_c('v-form',{ref:"formConfirmOTP"},[_c('div',{staticClass:"text-center",staticStyle:{"color":"#0c9f6b"}},[_vm._v(" Enter the Activation Code ")]),_c('v-text-field',{staticClass:"mt-4 FontSize",attrs:{"label":"Code *","autocomplete":"off","rules":[(v) => !!v || 'Code is required']},model:{value:(_vm.ConfirmOTP),callback:function ($$v) {_vm.ConfirmOTP=$$v},expression:"ConfirmOTP"}}),_c('v-text-field',{staticClass:"mt-4 FontSize",attrs:{"maxlength":"15","autocomplete":"off","label":"Enter New Password *","rules":[
                      (v) => !!v || 'Required',

                      (v) =>
                        (v && v.length < 15) ||
                        'Password must be less than 15 characters',
                      (v) =>
                        (v && v.length > 5) ||
                        'Password must be more than 5 characters',
                    ],"type":_vm.isPwdConfirmCode ? 'text' : 'password',"required":"","append-icon":_vm.isPwdConfirmCode ? 'mdi-eye' : 'mdi-eye-off',"prepend-inner-icon":"mdi-lock"},on:{"click:append":function($event){_vm.isPwdConfirmCode = !_vm.isPwdConfirmCode}},model:{value:(_vm.ConfirmNewPassword),callback:function ($$v) {_vm.ConfirmNewPassword=$$v},expression:"ConfirmNewPassword"}}),_c('div',{staticClass:"FontSize text-center",staticStyle:{"color":"#0c9f6b"}},[_vm._v(" Enter the Activation Code sent to your registered Email. ")])],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary","small":""},on:{"click":function($event){_vm.step = 1}}},[_vm._v("Back To Sign In")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","small":""},on:{"click":function($event){return _vm.ValidateEnteredOTP()}}},[_vm._v("Next")])],1)],1),_c('v-window-item',{attrs:{"value":5}},[_c('v-card-text',[_c('v-form',{ref:"EmailOTP"},[_c('div',[_vm._v("Enter Registered Email*")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"dense":"","outlined":"","label":"Email *","rules":[
                      (v) => !!v || 'Email is Required',
                      (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
                      (v) =>
                        (v && v.length < 50) ||
                        'Email must be less than 50 characters',
                    ]},model:{value:(_vm.Login_OTP_Email),callback:function ($$v) {_vm.Login_OTP_Email=$$v},expression:"Login_OTP_Email"}})],1)],1),_c('v-card-actions',{staticClass:"pt-0 pb-8 px-4"},[_c('v-btn',{staticClass:"gradient-bg",attrs:{"block":"","loading":_vm.Emailloading,"tile":"","color":"primary"},on:{"click":function($event){return _vm.ValidateEmailOTPForm()}}},[_vm._v("Request OTP")])],1),_c('v-card-actions',{staticClass:"pt-0 pb-8 px-4"},[_c('v-btn',{attrs:{"block":"","outlined":"","tile":""},on:{"click":function($event){_vm.step = 1}}},[_c('v-icon',{staticClass:"align-left"},[_vm._v("mdi-arrow-left")]),_vm._v(" Back To signIn")],1)],1)],1),_c('v-window-item',{attrs:{"value":6}},[_c('v-form',{ref:"formOTPForm",attrs:{"lazy-validation":""}},[_c('div',{staticClass:"py-2"},[_vm._v(" Enter OTP Sent to "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$store.getters.get_user_email)+" "),_c('v-btn',{attrs:{"small":"","outlined":""},on:{"click":function($event){_vm.step = 5}}},[_vm._v("Change")])],1)]),_c('v-otp-input',{attrs:{"dense":"","label":"","length":"6","required":"","rules":[(v) => !!v || 'Password is required']},on:{"click:append":function($event){_vm.isPwdNew = !_vm.isPwdNew}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-lock")])]},proxy:true}]),model:{value:(_vm.OTPRecieved),callback:function ($$v) {_vm.OTPRecieved=$$v},expression:"OTPRecieved"}})],1),_c('v-card-actions',{staticClass:"pa-0 pb-10 pt-4"},[_c('v-btn',{attrs:{"block":"","loading":_vm.loadingPassword,"tile":"","color":"primary"},on:{"click":function($event){return _vm.ValidateConfirmSignIn()}}},[_vm._v("Login")])],1)],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }