import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./store";
import { Amplify } from "aws-amplify";
import VueMoment from "vue-moment";
import * as VueGoogleMaps from "vue2-google-maps";
import SequentialEntrance from "vue-sequential-entrance";
import "./plugins/apexChart";
import "vue-sequential-entrance/vue-sequential-entrance.css";
Vue.use(SequentialEntrance);

Vue.use(VueAxios, axios);

Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
  load: {
    // key: "AIzaSyBUp8E7OghfEFg9fJ1ePokoOhE1I8MaD-M",
    key: "AIzaSyAVJ379DcRrp-ilBaqbLj5hZ5aUoV_Wu2w",
    libraries: "places",
  },
});

Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_SY1cn0Hjw",
    region: "ap-south-1",
    userPoolWebClientId: "1vp72vf3nalholl9jp8v6pk15i",
  },

  API: {
    aws_project_region: "ap-south-1",
    aws_appsync_graphqlEndpoint:
      "https://crddvxp4jngslj4ssqttdudhga.appsync-api.ap-south-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-south-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    endpoints: [
      {
        name: "Report_issue",
        endpoint:
          " https://61fvl4pi50.execute-api.ap-southeast-1.amazonaws.com/dev/",
      },
    ],
  },
});

Vue.use(require("vue-moment"));
new Vue({
  vuetify,
  router,
  VueMoment,
  store,
  render: (h) => h(App),
}).$mount("#app");
